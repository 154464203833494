import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from "axios";
import Prductslide from './Prductslide';
import Prductslide3 from './Prductslide3';

import Catslide from './Catslide';
import Catslide1 from './Catslide1';
import Catslide8 from './Catslide8';

import Bannerslideshow from './Bannerslideshow';
import Cookies from 'js-cookie';
import "./Prductslide.css";
import Product_rating from './Product_rating';
import { useTranslation } from './TranslationContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [state, setState] = useState({
    api_url: localStorage.getItem('api_url'),
    data: [],
    coo_product_id: localStorage.getItem('coo_product_id'),
    coo_product_name: localStorage.getItem('coo_product_name'),
    coo_product_image: localStorage.getItem('coo_product_image'),
    coo_price: localStorage.getItem('coo_price'),
    coo_quantity: localStorage.getItem('coo_quantity'),
    prescription: 'no',
    search: '',
    suc_cls: '',
    errors: {},
    openModal: false,
    pic: '',
    fileName: '',
    email: '',
    email1: '',
    password: ''
  });

  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = (e) => {
    e.preventDefault();
    navigate('/Stores');
  };

  const handleButtonClick1 = (e) => {
    e.preventDefault();
    navigate('/Dining');
  };

  const toggleModal = () => {
    setState(prevState => ({
      ...prevState,
      openModal: !prevState.openModal
    }));
  };

  useEffect(() => {
    // const api_url = localStorage.getItem('api_url');
    // axios.get(`${api_url}/gettrandproduct/`).then(response => console.log(response.data));
  }, []);

  return (
    <div className="">
      <Header_home />
      <div className='hea_img1'>
        <img className='hea_img2' src={require('./img/onlinedelivery.png')}  onClick={handleButtonClick}/>
        <img className='hea_img2' src={require('./img/dining.png')}  onClick={handleButtonClick1}/>
      </div>
     <br /><br />
     <p style={{color:"red",fontSize:"larger"}}>How it works</p>
      <h2 className='mar_place'><b>What We Serve</b></h2>
      <p className="bus_to"> Product Quality is Our Priority, And Always Guarantees Halal And Safety Until It Is In Your Hands </p>
      <div className='hea_img1'>
        <img className='hea_img' src={require('./img/easyto.png')} />
        <img className='hea_img' src={require('./img/fastest.png')} />
        <img className='hea_img' src={require('./img/best_qu.png')} />
      </div>
     <br /><br />
      <div className='sell_best_product' style={{backgroundColor:"#FF9E0C33"}}>
        <br /><br />
        <p style={{color:"red",fontSize:"larger"}}>Our menu</p>
      <h2 className='mar_place'><b>Our Popular Menu</b></h2>
      <p className="bus_to"> Menu that always make you to in love </p>
        <br /><br />
        <Catslide8 />
                <br /><br /><br />
      </div>

      <br /><br />
      <div className='sell_best_product'>
      <p style={{color:"red",fontSize:"larger"}}>Top categories</p>
      <h2 className='mar_place'><b>Top Categories for You</b></h2>      
        <Catslide />
        <br /><br /><br /><br />
      </div>

      <div className='sell_best_product' style={{backgroundColor:"#FF9E0C33"}}>
        <br /><br />
        <p style={{color:"red",fontSize:"larger"}}>Our Hotels</p>
      <h2 className='mar_place'><b>Our Popular Hotels</b></h2>
      <p className="bus_to"> Hotels are always make you to in love </p>
        <br /><br />
        <Catslide1 /> 
        <br /><br /><br />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
