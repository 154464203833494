import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import Prductslide from './Prductslide';
import axios from 'axios';
import Product_detail from './Product_detail';
import Product_sidebar from './Product_sidebar';
// import Loader from "react-loader-spinner";
// import { Puff } from "react-loader-spinner";
class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      category_id: localStorage.getItem('category_id'),
      data: [],
      product: {},
      product_detail: [],
      category_detail: [],
      subcategory_detail: [],
      prescription: 'no',
      search: '',
      suc_cls: '',
      errors: {},
      openModal: false,
      pic: '',
      fileName: '',
      email: '',
      email1: '',
      password: '',
      loading: true, // Initialize loading state here
    };
  }

  categoy_detailapi() {
   
    axios({
      method: 'get',
      url: this.state.api_url + '/getCategories/',
    }).then(res => {
      const data = res.data;
      this.setState({
        category_detail: data.categories,
      });
    });

    const sub_cat = localStorage.getItem('subcategory_id');
 
    if (sub_cat !== "") {
      axios({
        method: 'get',
        url: this.state.api_url + '/getSubcategories5/' + this.state.category_id,
      }).then(res => {
        const data = res.data;
        this.setState({
          subcategory_detail: data.sub_details1,
        });
      });
    }
  }

  componentDidMount() {
    this.categoy_detailapi();
    // setTimeout(() => {
    //   this.setState({ loading: false, showBlur: false }); // Set loading to false and hide blur effect after 2 seconds
    // }, 2000);
  }

  render() {
    return (

      
      <div className="">

          {/* <div className={this.state.showBlur ? "blur" : ""}>
              
              {this.state.loading ? (
                <div className="loader-wrapper">
                  <Puff type="Puff" color="#00BFFF" height={100} width={100} />
                </div>
              ) : (
                <div>Loading please wait...</div>
              )}
        </div> */}
        {<Header_home />}
        <Menu />
        <div className="short_line">
          <p className="short_text">Home / {this.state.subcategory_detail.cat_details} / {this.state.subcategory_detail.sub_cat_name}</p>
        </div>
        <h4 className='decor'>{this.state.subcategory_detail.cat_details}</h4>
        <div class="container">
          <div className='filter'>
            <div class="filter2">
              <h2 className='categ'>categories</h2>
              <Product_sidebar />
            </div>
            <br></br>
            {/* <div class="filter1">
              <h2 className='categ'>Filters</h2>
              <p className='categ'>BRANDS</p>
              <p className='categ'>PRODUCT TAGS</p>
              <p className='categ'>PRODUCT FORM</p>
              <p className='categ'>Sexual Wellness</p>
              <p className='categ'>USES</p>
              <p className='categ'>AGE</p>
              <p className='categ'>GENDER</p>
            </div> */}
          </div>
          <div className='itemss'>
            <Product_detail />
          </div>
          
        </div>
        <br></br>
        <br></br>
        {/* <div className='sell_best_product'>
          <h2 className="">Recently Viewed Products</h2>
          <Prductslide />
          <br></br>
          <br></br>
          <br></br>
        </div> */}
        {<Footer />}
      </div>
    );
  }
}

export default Product;
