import React from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide from './Prductslide';
// import TimeSlots from './TimeSlots';
import Catslide6 from './Catslide6';




 
  
 

class Dining extends React.Component{


  constructor(props) {
    super(props); 
    this.state = {
      api_url:localStorage.getItem('api_url'),
      user_id:localStorage.getItem('admin_id'),
        data:[],
        likecarts:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',email1:'',password:''
        
    };     
  }
 
  componentDidMount() {  
    window.scrollTo(0, 0);

}
  

      render(){
        return <div className="box"> {
          <Header_home /> } <br></br>
          <br></br>


         
          <div className='sell_best_product' >
            <h2 className='mar_place'><b>Trending dining our restaurants</b></h2>
            <Catslide6 />
            <br></br>
            <br></br>
            <br></br>
          </div> 
          {
          <Footer /> }
        </div>
      }
}

export default Dining;