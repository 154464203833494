import React, { useState, useEffect } from 'react';
import './style.css';
import Autocomplete from './Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';

const Header_home = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();
    const { translate, setLanguage } = useTranslation();

    // Load the language from localStorage when the component mounts
    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
        }
    }, [setLanguage]);

    const handleDivClick = () => { 
        navigate('/Home');
    };

    const handlelogout = () => {
        localStorage.clear();
        navigate('/Login');
    };

    const handlelike = () => {
        navigate('/Likecart');
    };

    const handlecart = () => {
        navigate('/Addcart');
    };

    const handleDivtrack = () => {
        navigate('/Myorders');
    };

    const handleDivtrack1 = () => {
        navigate('/Home');
    };

    const handleprofile = (id) => {
        setSelectedImage(id);
        navigate('/Profile');
    };

    const handleLanguageChange = (event) => {
        const selectedLang = event.target.value;
        setLanguage(selectedLang);
        localStorage.setItem('language', selectedLang);  // Save the selected language to localStorage
    };

    return (
        <div className="header">
            <div className="row">
                <div className='col-md-4'>
                    <img src={require('./img/tabelogo.png')} className="markettabe" style={{ cursor: 'pointer' }} onClick={handleDivtrack1} />
                </div>
                <div className='col-md-2' onClick={handleDivtrack}>
                    <p className="trackorder">{translate('trackOrder')}</p>
                </div>
                <div className='col-md-2'>
                    {/* <select className='language_name' onChange={handleLanguageChange} value={localStorage.getItem('language') || 'en'}>
                        <option value='en'>English</option>
                        <option value='ta'>Tamil</option>
                    </select> */}
                </div>
                <div className="col-md-3">
                    {/* <img className="heart" alt="Group" title="Like Cart" src={require('./img/heart.png')} onClick={handlelike} /> */}
                    <img className="vector" alt="Group" title="Cart" src={require('./img/Vector.png')} onClick={handlecart} />
                    <img className={`vector1 ${selectedImage === 'vector1' ? 'selected' : ''}`} alt="Group" title="Profile" onClick={() => handleprofile('vector1')} src={require('./img/Vector1.png')} />
                    <img className="logout" alt="Logout" title="Logout" src={require('./img/logout.png')} onClick={handlelogout} />
                </div>
            </div>

            <br></br>
            <br></br>
            <div className='hea_header'>
                <p>100% Healthy & Tasty</p>
            </div>
            <br></br>
            <div>
                <h1 className='hea_header1'><b>Be The Fastest In</b></h1>
                <h1 className='hea_header1'><b>Delivering Your <span style={{color:'red'}}>Food</span></b></h1>
            </div>
            <br></br>
            <div className="auto_search1" style={{width:"75%"}}>
                            <Autocomplete />
                        </div>

                        <br></br>
            <br></br>
            {/* <div className="row">
                <div className="col-md-9">
                    <p className="pro_logo1"></p>
                    <div className='select_product'>
                        <div className="auto_all1">
                            <select name="select_pro" value="" id="select_pro" className='select_pro'>
                                <option value="all">All</option>
                            </select>
                        </div>
                        <div className="auto_search1">
                            <Autocomplete />
                        </div>
                    </div>
                </div>
               
            </div> */}
        </div>
    );
};

export default Header_home;
