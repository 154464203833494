import React, { useState, useEffect } from "react";
import "./Ordercard1.css"; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Myfoods = () => {
  const [cards, setCards] = useState([]); // Stores food items
  const [totalPrice, setTotalPrice] = useState(0); // Total price of all items in the cart
  const navigate = useNavigate();
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const [cards3, setCards3] = useState([]);
  const [cards4, setCards4] = useState([]);
  const [cards5, setCards5] = useState([]);
  const [location, setLocation] = useState({ lat: 0, lng: 0 }); // Default location

  // useEffect(() => {
  //   const fetchCards = async () => {
  //     try {
  //       const user_id = localStorage.getItem("admin_id");
  //       const store_id = localStorage.getItem("store_id");
  //       const api_url = localStorage.getItem("api_url");

  //       const response = await axios.post(api_url + "/getstorefoods", {
  //         user_id,
  //         store_id,
  //       });

  //       const foodDetailsWithAmount = response.data.fooddetails.map((food) => ({
  //         ...food,
  //         totalAmount: food.price * food.quantity,
  //       }));

  //       setCards(foodDetailsWithAmount);

  //       const foodDetailsWithAmount = response.data.fooddetails.map((food) => ({
  //         ...food,
  //         showQuantityControls: false, // Initially hide quantity controls
  //         quantity: 0, // Initial quantity is 0
  //         totalAmount: 0, // Initial total amount is 0
  //       }));

  //       setCards(foodDetailsWithAmount);



  //       const response1 = await axios.get(api_url + '/getdinestorefoods/'+store_id);
  //       setCards5(response1.data.fooddetails);
  //       setCards1(response1.data.store_details);
  //       setCards2(response1.data.store_timeslots);
  //       setCards3(response1.data.image);
  //       setCards4(response1.data.images);

  //     // Get lat and lng from the API response
  //     const [lat, lng] = response.data.store_details.lat.split(',').map(Number);

  //     // Set the dynamic location state
  //     setLocation({ lat, lng });


  //     } catch (error) {
  //       console.error("Error fetching food items:", error);
  //     }
  //   };

  //   fetchCards();
  // }, []);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem("admin_id");
        const store_id = localStorage.getItem("store_id");
        const api_url = localStorage.getItem("api_url");
  
        // Fetch food items
        const response = await axios.post(api_url + "/getstorefoods", {
          user_id,
          store_id,
        });
  
        const foodDetailsWithAmount = response.data.fooddetails.map((food) => ({
          ...food,
          showQuantityControls: food.quantity > 0, // Show controls if quantity > 0
          quantity: food.quantity || 0, // Default to 0 if no quantity exists
          totalAmount: (food.price || 0) * (food.quantity || 0), // Default to 0 if data is missing
        }));
  
        setCards(foodDetailsWithAmount);
  
        // Fetch dine-in store foods
        const response1 = await axios.get(api_url + "/getdinestorefoods/" + store_id);
        setCards5(response1.data.fooddetails);
        setCards1(response1.data.store_details);
        setCards2(response1.data.store_timeslots);
        setCards3(response1.data.image);
        setCards4(response1.data.images);
  
        // Get lat and lng from the API response
        const [lat, lng] = response1.data.store_details.lat.split(",").map(Number);
        setLocation({ lat, lng });
      } catch (error) {
        console.error("Error fetching food items:", error);
      }
    };
  
    fetchCards();
  }, []);
  

  useEffect(() => {
    const calculatedTotalPrice = cards.reduce(
      (total, card) => total + card.totalAmount,
      0
    );
    setTotalPrice(calculatedTotalPrice);
  }, [cards]);

  const increaseQuantity = (product_id, price, currentQuantity) => {
    // Calculate the new quantity
    const newQuantity = currentQuantity + 1;

    const newprice = newQuantity * price;
  
    // Update the local state
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.product_id === product_id
          ? {
              ...card,
              quantity: newQuantity,
              totalAmount: newQuantity * price,
            }
          : card
      )
    );
  
    // Update the backend with the new quantity
    handleAddToCart5(product_id, newprice, newQuantity);
  };
  

  const decreaseQuantity = (product_id, currentQuantity, price) => {
    if (currentQuantity > 1) {

      const newprice = (currentQuantity - 1) * price;

      const newquantity = currentQuantity - 1;

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.product_id === product_id
            ? {
                ...card,
                quantity: card.quantity - 1,
                totalAmount: (card.quantity - 1) * price,
              }
            : card
        )
      );
      handleAddToCart5(product_id, newprice,newquantity);

    } else {
      // If quantity is 1, remove product from cart
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.product_id === product_id
            ? {
                ...card,
                showQuantityControls: false, // Hide quantity controls
                quantity: 0,
                totalAmount: 0,
              }
            : card
        )
      );

      handleAddToCart6(product_id, price,currentQuantity);
    }
  };

  const handleAddToCart = (product_id, price, cart_id, totprice, quantity) => {
    const newQuantity = quantity + 1;
  
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.product_id === product_id
          ? {
              ...card,
              showQuantityControls: true, // Show quantity controls
              quantity: newQuantity, // Increment quantity
              totalAmount: Number(price), // Ensure price is a number
            }
          : card
      )
    );
  
    handleAddToCart5(product_id, price, newQuantity);
  };

  const handleAddToCart5 = async (product_id, price, quantity) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const store_id = localStorage.getItem('store_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
        store_id,
      });

      // if (response.data.status) {
      //   alert("Item added to cart successfully!");

      // }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleAddToCart6 = async (product_id, price, quantity) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const store_id = localStorage.getItem('store_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/removetocart', {
        product_id,
        user_id,
        quantity,
        price,
        store_id,
      });

      // if (response.data.status) {
      //   alert("Item added to cart successfully!");

      // }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleAddToCart1 = async () => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const store_id = localStorage.getItem('store_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/finalocart', {
        user_id,
        price: totalPrice, // Use the total price
        store_id,
      });

      if (response.data.status === "true") {
        alert(response.data.message);
        navigate("/Addcart", { replace: true });
      } else {
        alert(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding all items to cart:", error);
    }
  };

  return (
    <div style={{ width: "100%" }}>

<div className='hea_img5'>
        <img className='hea_img2' src={cards3}  />
        <img className='hea_img2' src={cards3}  />
      </div>
      <div style={{textAlign:"left"}}>
        <h2>{cards1.store_name}</h2> 
        <p>{cards1.email}</p> 
        <p>{cards1.address_detail}</p>
        <p style={{color:"red"}}>{cards1.status === "1"? "Open Now":"closed"}<span style={{color:"#000000"}}>(Today)</span></p> 
      </div>
      <br></br>
      <h2 className="save_for">Our Foods ({cards.length})</h2>
      {cards.map((product) => (
        <div key={product.product_id}>
          <div className="order-card">
            <div className="product-info">
              <div className="product-details">
                <p className="product-name">
                  <b>{product.product_name}</b>
                </p>
                <p>{product.brand_name}</p>
                <p className="product-price">₹ {product.price}</p>

                {product.quantity > 0 ? (
                  <div>
                    <div className="quantity_details">
                      <div className="quantity-controls">
                        <button
                          className="quantity-button"
                          onClick={() =>
                            decreaseQuantity(
                              product.product_id,
                              product.quantity,
                              product.price
                            )
                          }
                        >
                          -
                        </button>
                        <span className="quantity">{product.quantity}</span>
                        <button
                          className="quantity-button"
                          onClick={() =>
                            increaseQuantity(product.product_id, product.price,product.quantity)
                          }
                        >
                          + 
                        </button>
                      </div>
                    </div>
                    <p className="total-amount">
                      Total: ₹ {product.totalAmount}
                    </p>
                  </div>
                ) : (
                  <button
                    className="add-to-cart-button"
                    onClick={() => handleAddToCart(product.product_id,product.price, product.cart_id, product.totalAmount, product.quantity)}
                  >
                    Add
                  </button>
                )}
              </div>
              <img
                className="product-image"
                src={product.product_image}
                alt={product.product_name}
              />
            </div>
          </div>
        </div>
      ))}
      {cards.length > 0 && (
        <div className="total-section">
          <p className="total-cart-price">Overall Total Price: ₹ {totalPrice}</p>
          <button className="add-to-cart-button1" onClick={handleAddToCart1}>
            Add All To Cart
          </button>
        </div>
      )}
      {cards.length === 0 && <p className="emptycart">Foods are empty</p>}
      <br />
    </div>
  );
};

export default Myfoods;
