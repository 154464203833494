import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';


const Ordercard = () => {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState({ totalamt: 0, ordertotal: 0, offer: 0 });
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [addressId, setAddressId] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(cards1.totalamt);
  const [totalPrice, setTotalPrice] = useState(0); // Total price of all items in the cart



  
 

  

  const handleSavetrans_pament = async (price,cart_id1) => {
    try {
      const user_id = localStorage.getItem('admin_id'); 
      const api_url = localStorage.getItem('api_url');






      const cart_id = cart_id1;
      
       const total_amt = price;
      const dateString = new Date();// Example date string
      const timestamp = Date.parse(dateString);
      const receiptid = Math.random().toString(36).substring(7);
      const transactionid = receiptid+'-'+timestamp;
      const currentDate = getCurrentDateTime;

      const response_add = await axios.get(`${api_url}/getAddressId/${user_id}`);
      if (response_add.data.address_id) {
        const address_id = response_add.data.address_id;
      
        const response = await axios.post(api_url + '/savetrancations', {
          user_id,
          cart_id,
          total_amt,
          transactionid,
          address_id
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: [(data, headers) => {
            const params = new URLSearchParams();
            for (const key in data) {
              params.append(key, data[key]);
            }
            return params.toString();
          }],
        });
      
       
      const check_res = response.data.receipt_id; 
      const user_detail = response.data.user_details;
      const add_detail = response.data.address;
  
      if (check_res) {
        
        const tot_amt = total_amt * 100;
        // const response = await axios.post(api_url +'/paymentorder');

        // const user_detail = await U.findOne({ _id: req.params.subcategory_id }).lean();
        
        const response = await axios.post(api_url + '/paymentorder', {
          user_id,
          cart_id,
          tot_amt,
          transactionid,
          address_id
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: [(data, headers) => {
            const params = new URLSearchParams();
            for (const key in data) {
              params.append(key, data[key]);
            }
            return params.toString();
          }],
        });




        const orderId  = await response.data.payment_id;
 
        const options = {
          key: 'rzp_test_JFlMQ0tOgzb3j8',
          amount: tot_amt, // amount in paise (50000 paise = ₹500)
          currency: 'INR',
          name: 'Medcall',
          receipt_no:check_res,
          description: 'Medcall Transactions',
          image: 'https://example.com/your_logo',
          order_id: orderId,
          handler: async function (response) {
           console.log(response.razorpay_payment_id);
           const rez_p = response.razorpay_payment_id;
           const status = 'purchased';
           const store_id = localStorage.getItem('store_id');
           const response2 = await axios.post(api_url + '/paymentorderupdate', {
            tot_amt,
            transactionid,
            rez_p,
            status,
            cart_id,
            store_id
            
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [(data, headers) => {
              const params = new URLSearchParams();
              for (const key in data) {
                params.append(key, data[key]);
              }
              return params.toString();
            }],
          });


          const payment_status = response2.data.status;

          if(payment_status){
            navigate("/Orderplace", { replace: true });
            alert('payment process succussfully completed');
          }




  
          },
          prefill: {
            name: user_detail.firstname,
            email: user_detail.email,
            contact: user_detail.phone_number,
          },
          notes: {
            address: add_detail.landmark+','+add_detail.building+','+add_detail.area+','+add_detail.district+','+add_detail.state+','+add_detail.country+' - '+add_detail.pincode,
          },
          theme: {
            color: '#3399cc',
          },
        };
 
        const rzp1 = new window.Razorpay(options);
        rzp1.open();

         
      }

       // Handle response
      } else {
        alert("Please add address and place order");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

 



  const handleCartremove = async (id,final_id,products_id,quant) => {

    try {

      //get product details
      const api_url = localStorage.getItem('api_url');
     
        const productss_id = id; 
        const user_id = localStorage.getItem('admin_id'); 
        const response = await axios.get(api_url + '/removecart/'+id+'/'+final_id)
        const check_res = response.data.status;
        if (check_res) {
          alert('Cart removed successfully');
          window.location.reload();
        }
      

     
    } catch (error) {
      console.error("Error adding to cart:", error);
    }

  };

 

  const handleCouponSelection = (coupon) => {

    if (coupon.amount_upto >= cards1.ordertotal) {
      alert('This coupon can only be applied to orders above ₹' + coupon.amount_upto);
      return;
    }

    setSelectedCoupon(coupon);

    // Calculate discount amount
    const discount = Math.min(coupon.offer, coupon.amount_upto);
    setDiscountAmount(discount);

    // Update total amount
    const newTotalAmount = cards1.totalamt - discount;
    setTotalAmount(newTotalAmount);
  };

  const user_id = localStorage.getItem('admin_id');


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };


  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getusercarts/'+user_id);
        setCards(response.data.cartItems[0].carts);
        setCards1({ cart_id:response.data.cartItems[0].cart_id,store_name:response.data.cartItems[0].store_name,totalamt: response.data.totalamt, ordertotal: response.data.ordertotal,offer: response.data.total_discount });
        setCoupons(response.data.coupon);

      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  useEffect(() => {
    const calculatedTotalPrice = cards.reduce(
      (total, card) => total + card.totalAmount,
      0
    );
    setTotalPrice(calculatedTotalPrice);
  }, [cards]);


  const increaseQuantity = (product_id, price, currentQuantity,tot) => {
    // Calculate the new quantity
    const newQuantity = currentQuantity + 1;  

    const newprice = newQuantity * price;
  
    // Update the local state
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.product_id === product_id
          ? {
              ...card,
              quantity: newQuantity,
              totalAmount: newQuantity * price,
            }
          : card
      )
    );
  
    // Update the backend with the new quantity
    handleAddToCart5(product_id, newprice, newQuantity,tot);
  };
  

  const decreaseQuantity = (product_id, currentQuantity, price,tot) => {
    if (currentQuantity > 1) {

      const newprice = (currentQuantity - 1) * price;

      const newquantity = currentQuantity - 1;

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.product_id === product_id
            ? {
                ...card,
                quantity: card.quantity - 1,
                totalAmount: (card.quantity - 1) * price,
              }
            : card
        )
      );
      handleAddToCart5(product_id, newprice,newquantity,tot);

    } else {
      // If quantity is 1, remove product from cart
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.product_id === product_id
            ? {
                ...card,
                showQuantityControls: false, // Hide quantity controls
                quantity: 0,
                totalAmount: 0,
              }
            : card
        )
      );

      handleAddToCart6(product_id, price,currentQuantity);
    }
  };

  const handleAddToCart = (product_id, price,cart_id, totprice, quantity) => {

    const newQuantity = quantity + 1;
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.product_id === product_id
          ? {
              ...card,
              showQuantityControls: true, // Show quantity controls
              quantity: 1, // Set initial quantity to 1
              totalAmount: price,
            }
          : card
      )
    );

    handleAddToCart5(product_id, price,newQuantity);
  };

  const handleAddToCart5 = async (product_id, price, quantity,tot) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const store_id = localStorage.getItem('store_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
        store_id,
      });

      handleAddToCart1(tot);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleAddToCart6 = async (product_id, price, quantity) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const store_id = localStorage.getItem('store_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/removetocart', {
        product_id,
        user_id,
        quantity,
        price,
        store_id,
      });

      handleAddToCart1(price);
    
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleAddToCart1 = async (totalPrice) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const store_id = localStorage.getItem('store_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/finalocart', {
        user_id,
        price: totalPrice, // Use the total price
        store_id,
      });

      if (response.data.status === "true") {
        window.location.reload();
      } else {
        alert(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding all items to cart:", error);
    }
  };





  return (
       <><div className='price_amount1'>
       <div>
      {/* Display the store name */}
      <h2 className="store-name">{cards1.store_name}</h2>
      <hr />

      {/* Display products within the cart */}
      {cards.length !== 0 && (
        cards.map((product) => (
        <div key={product.product_id}>
          <div className="order-card1">
            <div className="product-info">
              {/* Product Image */}

              <button className="product_remove" onClick={() => handleCartremove(product.cart_id,product.cartfinal_id, product.product_id, product.quantity)}> X </button>

              <img className="product-image" src={product.product_image} alt={product.product_name} />

              {/* Product Details */}
              <div className="product-details">
                <p className="product-name"><b>{product.product_name}</b></p>
                {/* Conditional Pricing: Showing discount price if available */}
                <p className="product-price">
                  {product.discount_price && parseFloat(product.discount_price) < product.price ? (
                    <>
                      <span className='original-price'>₹{product.price}</span>
                      <span className='final-price'> ₹{product.discount_price}</span>
                    </>
                  ) : (
                    <span className='final-price'> ₹{product.price}</span>
                  )}
                </p>
                {product.quantity > 0 ? (
                  <div>
                    <div className="quantity_details">
                      <div className="quantity-controls">
                        <button
                          className="quantity-button"
                          onClick={() =>
                            decreaseQuantity(
                              product.product_id,
                              product.quantity,
                              product.price,
                              product.totalAmount
                            )
                          }
                        >
                          -
                        </button>
                        <span className="quantity">{product.quantity}</span>
                        <button
                          className="quantity-button"
                          onClick={() =>
                            increaseQuantity(product.product_id, product.price,product.quantity,product.totalAmount)
                          }
                        >
                          + 
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    className="add-to-cart-button"
                    onClick={() => handleAddToCart(product.product_id,product.price, product.cart_id, product.totalAmount, product.quantity)}
                  >
                    Add
                  </button>
                )}


              </div>
            </div>
          </div>
          <hr />
        </div>
 )))}    </div>
{cards.length !== 0 && (
  <button className="place_del" onClick={() => handleSavetrans_pament(totalAmount.toFixed(2) > 1 ? totalAmount.toFixed(2) : cards1.totalamt,cards1.cart_id)}>{translate('place_order')}</button>
  )}

      {cards.length === 0 && (
        <p className="emptycart">{translate('cart_empty')}</p>
      )}


      <br></br>
      <p> </p>
    </div><br></br>
    
    <div className='payment_gate'>
      <div className='pay_detail'>
        <div className='coupon_app'>
          <p className='coupon_a'>{selectedCoupon ? `Coupon applied: ${selectedCoupon.coupon_code}` : 'No coupon applied'}</p>
         
        </div>

        <p><b>{translate('payment_summary')}</b></p>

        <div className='orde_tot'>
          <p>{translate('order_total')}</p>
          <p className='orde_pri'>₹ {cards1.ordertotal}</p>
        </div>
        <div className='orde_tot'>
          <p>{translate('items_discount')}</p>
          <p className='orde_pri1'>-₹ {cards1.offer}</p>
        </div>
        <div className='orde_tot'>
          <p>{translate('coupon_discount')}</p>
          <p className='orde_pri1'>-₹ {discountAmount.toFixed(2)}</p>
        </div>
        <div className='orde_tot'>
          <p>{translate('shipping')}</p>
          <p className='orde_pri2'>Free</p>
        </div>

        <hr />
        <div className='orde_tot'>
          <p><b>{translate('total')}</b></p>
            <p className='orde_pri3'>
            ₹ {totalAmount && totalAmount > 0 ? totalAmount.toFixed(2) : cards1.totalamt}
            </p>
        </div>
        <hr />
        <span className='save_amount'>{translate('you_will_save')} ₹ {discountAmount.toFixed(2)} {translate('available_coupons')}</span>

        <div className='coupon_selection'>
          <p><b>Available Coupons</b></p>
          {coupons.map(coupon => (
            <div key={coupon._id}>
              <input
                type="radio"
                id={coupon.coupon_code}
                name="coupon"
                value={coupon.coupon_code}
                checked={selectedCoupon && selectedCoupon.coupon_code === coupon.coupon_code}
                onChange={() => handleCouponSelection(coupon)}
              />
              <label htmlFor={coupon.coupon_code}>{coupon.coupon_code} - {coupon.offer}% off up to ₹{coupon.amount_upto}</label>
              <p>Valid Up To: {formatDate(coupon.valid_date)}</p>
            </div>
          ))}
        </div>
      </div>
    </div></>
    
  );
};

export default Ordercard;
