import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';


const Addresschange = () => {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState({});
  const [cards2, setCards2] = useState(null); // Initialize cards2 as null
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const { translate } = useTranslation();

  const togglePopup1 = () => {
    setShowPopup(!showPopup);
  };

  const handleAddressChange = async (e, id) => {
    const address_id = id;
    const user_id = localStorage.getItem('admin_id'); 
    const api_url = localStorage.getItem('api_url');
    try {
      const response = await axios.post(api_url + '/updateuseraddress1', {
        user_id,
        address_id,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });

      const check_res = response.data.status;
      if (check_res) {
        alert("Address changed successfully!");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getuseraddress/'+user_id);
        setCards(response.data.address_details);
        setCards1(response.data.user_details);
        setCards2(response.data.add_d);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div>
      <div className="short_line1">
        <div className='delivery_details'>
          {cards2 ? (
            <>
              <p className='delivery_name'><b>{translate('deliver_to')}: </b>{cards1.firstname ? `${cards1.firstname} ${cards1.lastname || ''}` : translate('Guest')}</p>
              <p className='delivery_add'>{cards2.address_detail}</p>
            </>
          ) : (
            <p className='not-found-message'>{translate('address_not_found')}</p>
          )}
        </div>
      
        <div className='delivery_btn'>
          <button className='delivery_btn1' onClick={togglePopup1}>{translate('change')}</button>
        </div>
      </div>

      {showPopup && (
        <div className='popup-container'>
          <div className='popup'>
            <div className='popup-inner'>
              <img className='add_edit1' onClick={togglePopup1} src={require('./img/cancel.png')} />
              <p className='lable_name1'><b>{translate('change_address')}</b></p>
              <div className='grid-container1'>
                {cards && cards.map((add) => (
                  <div key={add._id} className='address-card'>
                    <input
                      type='radio'
                      id={add._id}
                      name='address'
                      value={add._id}
                      className='address-radio'
                      defaultChecked={add.active === 1}
                      onChange={(e) => handleAddressChange(e, add._id)}
                    />
                    <label htmlFor={add._id}>
                      <p className='delivery_name1'> {cards1.firstname + ' ' + cards1.lastname}</p>
                    </label>
                    <p className='add_list'>{add.address_detail}</p>
                    
                    <hr></hr>
                  </div>
                ))}
              </div>
              <br></br>
            </div>
          </div>
          <div className='popup-overlay'></div>
        </div>
      )}
    </div>
  );
};

export default Addresschange;
