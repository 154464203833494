import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const TimeSlots = ({ store_timeslots }) => {
  const [groupedTimeSlots, setGroupedTimeSlots] = useState({});
  const [activeTab, setActiveTab] = useState('Breakfast');
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);
  const mealOrder = ['Breakfast', 'Lunch', 'Dinner'];
  const navigate = useNavigate();


  // Dynamically group the time slots by meal type
  useEffect(() => {
    const groupSlotsByMeal = () => {
      const grouped = store_timeslots.reduce((acc, slot) => {
        const mealType = slot.meal;
        if (!acc[mealType]) {
          acc[mealType] = [];
        }
        acc[mealType].push(slot);
        return acc;
      }, {});
      setGroupedTimeSlots(grouped);
    };

    groupSlotsByMeal();
  }, [store_timeslots]);

  const handleBooking = async () => {
    if (!selectedDate || !selectedGuests || !selectedTimeSlotId) {
      alert('Please select a date, number of guests, and a time slot');
      return;
    }
    const user_id = localStorage.getItem('admin_id'); 

    const api_url = localStorage.getItem('api_url');
    const response = await axios.post(api_url + '/userbooking', {
       date:selectedDate,
      user_id,
      guests:selectedGuests,
      timeslot_id:selectedTimeSlotId,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: [(data, headers) => {
        const params = new URLSearchParams();
        for (const key in data) {
          params.append(key, data[key]);
        }
        return params.toString();
      }],
    });
    const check_res = response.data.status;

    if (check_res) {
      alert(`Booking confirmed for ${selectedGuests} guests on ${selectedDate} at time slot ID: ${selectedTimeSlotId}`);
      navigate("/MyDineorders", { replace: true });
    }
  };

  const getDateOptions = () => {
    const options = [];
    const today = new Date();
    for (let i = 0; i < 6; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      options.push(date.toISOString().split('T')[0]);
    }
    return options;
  };

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedGuests, setSelectedGuests] = useState(1);

  const handleTimeSlotSelection = (id) => {
    setSelectedTimeSlotId(id); // Set the selected time slot ID
  };

  return (
    <div>
      {/* Tabs for Breakfast, Lunch, and Dinner */}
      <div className="tabs1">
        {mealOrder.map((meal) => (
          groupedTimeSlots[meal] && (
            <button
              key={meal}
              className={activeTab === meal ? 'active' : ''}
              onClick={() => setActiveTab(meal)}
            >
              {meal}
            </button>
          )
        ))}
      </div>

      {/* Date and Guests Selection */}
      <div className="date-guests-selection">
        <div className="select-group">
          <label>Select Date:</label>
          <select
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            <option value="">-- Select a Date --</option>
            {getDateOptions().map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>

        {/* Guest Selection */}
        <div className="select-group">
          <label>Number of Guests:</label>
          <select
            value={selectedGuests}
            onChange={(e) => setSelectedGuests(Number(e.target.value))}
          >
            {[1, 2, 3, 4, 5, 6].map((guest) => (
              <option key={guest} value={guest}>
                {guest} {guest === 1 ? 'Guest' : 'Guests'}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Display time slots based on the selected tab */}
      <div className="timeslots-container">
        {groupedTimeSlots[activeTab] &&
          groupedTimeSlots[activeTab].map((slot) => (
            <button
              key={slot._id}
              className={`timeslot-button ${selectedTimeSlotId === slot._id ? 'selected' : ''}`}
              onClick={() => handleTimeSlotSelection(slot._id)} // Call function on click
            >
              {slot.timeslote}
            </button>
          ))}
      </div>

      {/* Booking Button */}
      <div className="booking-button-container">
        <button className="booking-button" onClick={handleBooking}>
          Book Now
        </button>
      </div>

      <style jsx>{`
        .tabs1 {
          display: flex;
          justify-content: space-around;
          margin-bottom: 20px;
        }

        .tabs1 button {
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          border: none;
          background-color: #ddd;
          border-radius: 5px;
          transition: background-color 0.3s;
        }

        .tabs1 button.active {
          background-color: #b89d4d;
          color: white;
        }

        .date-guests-selection {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .select-group {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
        }

        .select-group label {
          font-weight: bold;
          margin-bottom: 5px;
        }

        select {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 16px;
        }

        .timeslots-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr); /* 4 time slots per row */
          gap: 10px;
        }

        .timeslot-button {
          padding: 10px;
          background-color: #f0f0f0;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .timeslot-button.selected {
          background-color: #007bff;
          color: white;
        }

        .timeslot-button:hover {
          background-color: #007bff;
          color: white;
        }

        .booking-button-container {
          text-align: center;
          margin-top: 20px;
        }

        .booking-button {
          padding: 10px 20px;
          font-size: 16px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .booking-button:hover {
          background-color: #218838;
        }
      `}</style>
    </div>
  );
};

export default TimeSlots;
